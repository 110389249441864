import React from 'react';
import {BCLB_NUMBER, BRAND, COMPANY_NAME, SOCIAL_MEDIA} from "../../../../Helper";
import VerifiedIcon from '@mui/icons-material/Verified';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import {Link} from "react-router-dom";

export default class Footer extends React.Component {

    userAgreement = () => {
        Event.emit('show_agreement');
    }

    privacyPolicy = () => {
        Event.emit('show_privacy');
    }

    verify = () => {
        Event.emit('show_verify');
    }

    render() {
        return (
            <>
                <footer style={{margin: '0 12px'}}>
                    <hr className={"mt-1 mb-1"}/>
                    <span style={{fontSize: '13px', fontWeight: 'bold'}} className={'text-custom-yellow'}>SUPPORT AND COUNSELLING</span>
                    <div style={{fontSize: '11px'}} className={"text-white"}>
                        <ul>
                            <li>
                                <Link style={{textDecoration: 'none', fontWeight: 'bold', color: 'white'}} to={'/terms'}>Terms and Conditions</Link>
                            </li>
                            <li>
                                <Link style={{textDecoration: 'none', fontWeight: 'bold', color: 'white'}} to={'/responsible'}>Responsible Gambling</Link>
                                <br/>
                                &#10003;&nbsp;<a style={{textDecoration: 'none', fontWeight: 'bold', color: '#FF5E00'}} target={'_blank'} rel={'GambleAware'} href={'https://www.gambleaware.org'}>Gamble Aware</a>
                                <br/>
                                &#10003;&nbsp;<a style={{textDecoration: 'none', fontWeight: 'bold', color: '#FF5E00'}} target={'_blank'} rel={'Gamcare'} href={'https://www.gamcare.org.uk'}>Gamcare</a>
                            </li>
                            <li>
                                <Link style={{textDecoration: 'none', fontWeight: 'bold', color: 'white'}} to={'/privacy'}>Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>

                    <hr className={"mt-1 mb-1"}/>
                    <span style={{fontSize: '13px', fontWeight: 'bold'}} className={'text-custom-yellow'}>RESPONSIBLE GAMBLING</span>
                    <div style={{fontSize: '11px'}} className={"text-white"}>
                        This is a real-money gambling app. Please gamble responsibly and only bet what you can afford.
                        For gambling addiction help and support, please contact CustomerCare at (+254794794400), or visit ( Responsible Gambling Website <a style={{textDecoration: 'none', fontWeight: 'bold', color: '#FF5E00'}} target={'_blank'} rel={'responsiblegambling'} href={'https://responsiblegambling.or.ke'}>(https://responsiblegambling.or.ke)</a>.
                        For more information, please view our Responsible Gaming Policy here <a style={{textDecoration: 'none', fontWeight: 'bold', color: '#FF5E00'}} target={'_blank'} rel={'responsible-gaming'} href={'https://help.ligibet.co.ke/responsible-gaming'}>(https://help.ligibet.co.ke/responsible-gaming)</a>
                    </div>

                    <hr className={"mt-1 mb-1"}/>
                    <span style={{fontSize: '13px', fontWeight: 'bold'}} className={'text-custom-yellow'}>LEGAL AND COMPLIANCE</span>
                    <div style={{fontSize: '11px'}} className={"text-white"}>
                        <div style={{borderRadius: '50%', backgroundColor: 'white', width: '20px', height: '20px'}}>
                            <img className={"p-1"} width={'20px'} src="/assets/images/age.png"/>
                        </div>
                        Must be 18+ to register or play. Please gamble responsibly.
                    </div>

                    <hr className={"mt-1 mb-1"}/>
                    <span style={{fontSize: '13px', fontWeight: 'bold'}} className={'text-custom-yellow'}>LICENCE</span>
                    <div style={{fontSize: '11px'}} className={"text-white"}>
                        {COMPANY_NAME}, Trading as {BRAND}, is licensed by the Betting Control & Licensing Board under License Number {BCLB_NUMBER}.
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 text-center bet-area"}>
                            <span style={{marginRight: 'auto', alignItems: 'center'}}><VerifiedIcon color={'success'}/>&nbsp;<span>Provably Fair</span></span>
                            <a className={"p-1"} href={SOCIAL_MEDIA['wa']} target={"_blank"}><WhatsAppIcon fontSize={'large'} style={{color: '#04A784'}}/></a>
                            <a className={"p-1"} href={SOCIAL_MEDIA['fb']} target={"_blank"}><FacebookIcon fontSize={'large'} style={{color: '#0B88EF'}}/></a>
                            <a className={"p-1"} href={SOCIAL_MEDIA['tw']} target={"_blank"}><TwitterIcon fontSize={'large'} style={{color: '#188CD8'}}/></a>
                            <a className={"p-1"} href={SOCIAL_MEDIA['in']} target={"_blank"}><InstagramIcon fontSize={'large'} style={{color: '#C52480'}}/></a>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}